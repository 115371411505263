@use "_mixins" as *;

$spacing-base: 8px;
$border-radius: 8px;

$layout-multiplier: 1;
$layout-xl-multiplier: 1.25;
$layout-tv-multiplier: 1.75;

@mixin byScreen {
  @content ($layout-multiplier);

  @include breakpoint(xl) {
    @content ($layout-xl-multiplier);
  }

  @include breakpoint(tv) {
    @content ($layout-tv-multiplier);
  }
}
