@use "_colors";
@use "_mixins" as *;
@use "_layout";
@use '_keyframes' as *;

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: colors.$gray;

  .sidebar__icon {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    color: white;

    &--spinning {
      animation: spin 2s infinite linear;
    }
  }

  &--disabled {
    pointer-events: none;
  }
}

.sidebar__content {
  flex-grow: 2;
}

@include layout.byScreen using ($layout-multiplier) {
  .sidebar__header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .header__search {
      display: flex;
      flex-direction: column;

      margin: layout.$spacing-base * 2 * $layout-multiplier;
      @include spacing($vertical, layout.$spacing-base * $layout-multiplier);

      .search__form {
        display: flex;
        height: 40px * $layout-multiplier;
        font-size: 16px * $layout-multiplier;

        .search__input {
          flex-grow: 2;
          margin: 0;
          padding: (layout.$spacing-base * $layout-multiplier)
            (layout.$spacing-base * 2 * $layout-multiplier);

          border-radius: 0;
          border-top-left-radius: (layout.$border-radius * $layout-multiplier);
          border-bottom-left-radius: (
            layout.$border-radius * $layout-multiplier
          );
        }

        .search__button {
          border-radius: 0;
          border-top-right-radius: (layout.$border-radius * $layout-multiplier);
          border-bottom-right-radius: (
            layout.$border-radius * $layout-multiplier
          );
          padding: 0 (layout.$spacing-base * $layout-multiplier);
        }
      }

      .search__message {
        color: white;
        border-radius: (layout.$border-radius * $layout-multiplier);
        background-color: colors.$error;
        padding: (layout.$spacing-base * $layout-multiplier);
        text-align: center;
      }
    }
  }

  .sidebar__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: (layout.$spacing-base * $layout-multiplier)
      (layout.$spacing-base * 2 * $layout-multiplier);
    flex-shrink: 0;

    .footer__loading_indicator {
      display: flex;
      align-items: center;
      @include spacing($horizontal, layout.$spacing-base * $layout-multiplier);

      .loading_indicator__last_updated {
        color: white;
        font-size: 14px * $layout-multiplier;
      }
    }

    .footer__sign_out {
      flex-shrink: 0;
      padding: (layout.$spacing-base * $layout-multiplier)
        (layout.$spacing-base * 2 * $layout-multiplier);
      background-color: colors.$dark-gray;
    }
  }
}
