@use "_colors";
@use "_mixins" as *;
@use "_layout";
@use '_keyframes' as *;

@mixin color_indication($color) {
  background-color: $color;

  animation-name: blinking_animation;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 1s;

  @keyframes blinking_animation {
    0% {
      box-shadow: 0px 0px 5px 1px rgba($color, 0.4);
      background-color: rgba($color, 0.8);
    }
    50% {
      box-shadow: 0px 0px 15px 2px rgba($color, 0.4);
      background-color: $color;
    }
    100% {
      box-shadow: 0px 0px 5px 1px rgba($color, 0.4);
      background-color: rgba($color, 0.8);
    }
  }
}

.loading_signal {
  @include layout.byScreen using ($layout-multiplier) {
    $size: layout.$spacing-base * $layout-multiplier;

    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;
    border-radius: $size / 2;
  }

  &--loading {
    @include color_indication(colors.$teal);
  }

  &--default {
    @include color_indication(colors.$green);
  }
}
