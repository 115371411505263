@use "_colors";
@use "_mixins" as *;
@use "_layout";

.vessel_info {
  display: flex;
}

.vessel_info__section {
  flex-grow: 1;
  flex-basis: 0;

  &--large {
    flex-grow: 2;
  }
}

@include layout.byScreen using ($layout-spacing) {
  .vessel_info__details {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    @include spacing($horizontal, layout.$spacing-base * $layout-spacing);

    .details__name {
      font-size: 18px * $layout-spacing;
      font-weight: bold;
    }

    .details__extra {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      @include spacing($vertical, layout.$spacing-base * $layout-spacing / 2);
    }

    .details__image {
      width: 80px * $layout-spacing;
      height: 60px * $layout-spacing;
      border-radius: 4px * $layout-spacing;
      object-fit: cover;
    }

    .details__text {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      @include spacing($vertical, layout.$spacing-base * $layout-spacing);
    }
  }

  .vessel_info__destination {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @include spacing($vertical, layout.$spacing-base * $layout-spacing / 2);

    .destination__header {
      display: flex;
      justify-content: space-between;
    }

    .destination__title {
      font-size: 18px * $layout-spacing;
      font-weight: bold;
    }

    .destination__total {
      color: white;
      background-color: colors.$lighter-gray;
      padding: (layout.$spacing-base * $layout-spacing / 2)
        (layout.$spacing-base * $layout-spacing);
      border-radius: 20px * $layout-spacing;
      font-weight: bold;
      flex-shrink: 0;
    }

    .details__text {
      display: flex;

      & > * {
        flex-grow: 2;
        flex-basis: 0;
        flex-shrink: 0;
      }
    }
  }
}
