@use "_colors";
@use "_mixins" as *;
@use "_layout";

.map {
  background-color: colors.$map-sea;
  height: 100%;
}

:global {
  .map_marker {
    @include layout.byScreen using ($layout-multiplier) {
      padding: (4px * $layout-multiplier) (8px * $layout-multiplier);
      border-radius: (12px * $layout-multiplier);

      background-color: #565a61;
      color: #ffffff !important;

      // Port
      &__P {
        background-color: #666666;
        color: #fefefe !important;
        opacity: 0.5;
      }

      &__P,
      &__V,
      &__F {
        &--selected {
          background-color: white;
          color: #666666 !important;
          opacity: 1;
        }
      }
    }
  }

  .gm-style > div {
    // Google is adding a blue border to the map when selected via keyboard; I
    // assume it's for  accessibility purposes, and not trusting the browser's
    // behavior, Google is adding a blue border on its own.
    // Since this is an internal tool that'll be mostly used in tablets, we'll
    // "hide" the border.
    border-color: transparent !important;
  }
}
