@use "_colors";
@use "_mixins" as *;
@use "_layout";

.factory_info {
  display: flex;
}

.factory_info__section {
  flex-grow: 1;
  flex-basis: 0;

  &--large {
    flex-grow: 2;
  }
}

@include layout.byScreen using ($layout-spacing) {
  .factory_info__details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @include spacing($vertical, layout.$spacing-base * $layout-spacing);

    .details__name {
      font-size: 18px * $layout-spacing;
      font-weight: bold;
    }

    .details__extra {
      display: flex;
      align-items: center;
      @include spacing($horizontal, layout.$spacing-base * $layout-spacing * 2);
    }

    .details__image {
      width: 80px * $layout-spacing;
      height: 60px * $layout-spacing;
      border-radius: 4px * $layout-spacing;
      object-fit: cover;
    }
  }
}
