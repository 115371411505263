@use "_colors";
@use "_mixins" as *;
@use "_layout";

@include layout.byScreen using ($layout-spacing) {
  .cargo_breakdown {
    $item-spacing: layout.$spacing-base * $layout-spacing / 2;

    display: flex;
    flex-wrap: wrap;
    margin: -$item-spacing;

    .cargo_breakdown__placeholder {
      color: white;
      background-color: colors.$lighter-gray;
      margin: layout.$spacing-base * $layout-spacing / 2;
      padding: (layout.$spacing-base * $layout-spacing / 2)
        (layout.$spacing-base * $layout-spacing);
      border-radius: layout.$border-radius * $layout-spacing / 2;
    }

    .models__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: white;
      background-color: colors.$lighter-gray;

      padding: (layout.$spacing-base * $layout-spacing / 2)
        (layout.$spacing-base * $layout-spacing);
      margin: layout.$spacing-base * $layout-spacing / 2;

      border-radius: layout.$border-radius * $layout-spacing / 2;

      @include spacing($horizontal, layout.$spacing-base * $layout-spacing);
    }

    .item__name {
      font-weight: bold;
    }

    .item__total {
      background-color: colors.$even-lighter-gray;
      color: colors.$light-gray;
      font-weight: bold;

      padding: (layout.$spacing-base * $layout-spacing / 2)
        (layout.$spacing-base * $layout-spacing);
      border-radius: (layout.$border-radius * $layout-spacing / 2);
    }

    .item__breakdown {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      @include spacing($horizontal, layout.$spacing-base * $layout-spacing);

      .breakdown__single {
        b {
          font-weight: bold;
        }
      }
    }
  }
}
