@use 'sass:color';
//------------------------------------------------------------------------------
// Basic -----------------------------------------------------------------------
$white: #ffffff;
$gray: #191f22;
$black: #232323;
$red: rgb(204, 0, 0);
$green: #00e676;
$blue: #1976d2;
$teal: #ffb300;
// Acqua -----------------------------------------------------------------------
$aqua-green: rgb(237, 247, 237);
$aqua-blue: rgb(232, 244, 253);
$aqua-yellow: rgb(255, 244, 229);
$aqua-red: rgb(253, 236, 234);
// Light -----------------------------------------------------------------------
$light-gray: #313639;
$lighter-gray: #808080;
$even-lighter-gray: #e0e0e0;
// Dark ------------------------------------------------------------------------
$dark-gray: rgb(51, 51, 51);
//------------------------------------------------------------------------------
// Background ------------------------------------------------------------------
$background: rgb(27, 29, 31);
$overlay: rgba(0, 0, 0, 0.3);
//------------------------------------------------------------------------------
// Borders ---------------------------------------------------------------------
$border-gray: rgb(204, 204, 204);
//------------------------------------------------------------------------------
// Text ------------------------------------------------------------------------
$text-black: #000000;
$text-dark-gray: rgb(30, 70, 32);
$error: #e53935;
//------------------------------------------------------------------------------
// Google Maps -----------------------------------------------------------------
$map-sea: #191919;
//------------------------------------------------------------------------------
// States ----------------------------------------------------------------------
@function hover($color) {
  @return color.adjust($color, $lightness: -3%);
}
@function active($color) {
  @return color.adjust($color, $lightness: -6%);
}
