@use "_mixins" as *;
@use "_colors";
@use "_layout";

.welcome__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: layout.$spacing-base * 10;

  width: 100%;
  min-height: 100%;

  background-image: url("~@assets/img/welcome/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  box-shadow: rgba(12, 0, 12, 0.6) 0px 0px 0px 2000px inset;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 80%;
  max-width: 400px;
  @include spacing($vertical, layout.$spacing-base * 6);
}

.welcome__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include spacing($vertical, layout.$spacing-base * 3);

  .header__logo {
    width: 80px;
  }

  .header__title {
    font-weight: bold;
    font-size: 38px;
    color: white;
    text-align: center;
  }
}

.welcome__form {
  display: flex;
  flex-direction: column;
  width: 80%;
  @include spacing($vertical, layout.$spacing-base * 3);

  & > * {
    flex-basis: 1;
    height: 44px;
  }

  .form__input {
    border-radius: layout.$border-radius;
    padding: layout.$spacing-base layout.$spacing-base * 2;
  }

  .form__submit {
  }

  .form__error {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: layout.$border-radius;

    background-color: colors.$error;
    padding: layout.$spacing-base layout.$spacing-base * 2;
    color: white;
  }

  &--submitted {
    .form__input:invalid {
      box-shadow: 0px 0px 1px 3px colors.$error;
    }
  }
}
