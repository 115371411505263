@use "_colors";
@use "_layout";
@use "_keyframes" as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: bold;

  background-color: colors.$red;
  border-radius: layout.$border-radius;
  cursor: pointer;

  .button__span {
    color: inherit;
    font-weight: inherit;
  }

  .button__loading_indicator {
    animation: spin 2s infinite linear;
  }

  &--disabled {
    pointer-events: none;
  }
}
