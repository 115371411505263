// To-do:
// 1. Look into Sass maps (https://sass-lang.com/documentation/values/maps)
// 2. Replace multiple if statements with a single interpolation ($screen-#{$size}-min)

$screen-xs-min: 320px;
$screen-sm-min: 576px;
$screen-md-min: 868px;
$screen-lg-min: 992px;
$screen-xl-min: 2000px;
$screen-tv-min: 2400px;

@mixin breakpoint($size) {
  @if $size == xs {
    @media only screen and (max-width: $screen-xs-min) {
      @content;
    }
  } @else if $size == sm {
    @media only screen and (max-width: $screen-sm-min) {
      @content;
    }
  } @else if $size == md {
    @media only screen and (max-width: $screen-md-min) {
      @content;
    }
  } @else if $size == lg {
    @media only screen and (max-width: $screen-lg-min) {
      @content;
    }
  } @else if $size == xl {
    @media only screen and (min-width: $screen-xl-min) {
      @content;
    }
  } @else if $size == tv {
    @media only screen and (min-width: $screen-tv-min) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $size) {
      @content;
    }
  }
}

$vertical: "vertical";
$horizontal: "horizontal";

@mixin spacing($direction, $spacing) {
  @if $direction == $vertical {
    & > * {
      margin-bottom: $spacing;
      &:last-child {
        margin-bottom: 0;
      }
    }
  } @else {
    & > * {
      margin-right: $spacing;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// Chrome (v84.x), for some reason, struggles when updating the outline of an
// input. The page becomes unresponsive, a key stroke takes 100ms to execute and
// it feels as if the computer's about to lift up and fly. 🚁
//
// This is a hacky solution that should be removed as soon as the new version
// comes and the issue's fixed.
//
// Inputs are pretty ugly there right now (as we only style them for Safari,
// Firefox, Opera etc) but the experience is great. A fair trade-off.
@mixin safe_input_styling {
  @media not all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    @content;
  }
}
