@use "_colors";
@use "_mixins" as *;
@use "_layout";

.factories_list {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .list__section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .section__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;

      .header__factories {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-transform: capitalize;

        .factories__name {
          display: flex;
          height: auto;
          line-height: normal;
          text-decoration: underline;
          cursor: pointer;
        }

        .factories__icon {
          background-color: colors.$lighter-gray;
        }
      }

      .total__value {
        font-weight: bold;
      }
    }

    .section__cars {
      display: flex;
      flex-direction: column;
      background-color: colors.$light-gray;

      .section__car {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
      }

      .car__total {
        color: white;
        background-color: colors.$lighter-gray;
        font-weight: bold;
        flex-shrink: 0;
      }

      .section__placeholder {
        color: white;
      }

      &--empty {
        align-items: center;
        justify-content: center;
      }
    }
  }

  @include layout.byScreen using ($layout-spacing) {
    padding: layout.$spacing-base * 2 * $layout-spacing;
    @include spacing($vertical, layout.$spacing-base * 3 * $layout-spacing);

    .list__section {
      @include spacing($vertical, layout.$spacing-base * $layout-spacing);

      .section__header {
        padding: 0 layout.$spacing-base * $layout-spacing;

        .header__factories {
          @include spacing(
            $horizontal,
            layout.$spacing-base * $layout-spacing / 2
          );

          .factories__icon {
            border-radius: 20px * $layout-spacing;
            width: 20px * $layout-spacing;
            height: 20px * $layout-spacing;
          }
        }
      }

      .section__cars {
        padding: layout.$spacing-base * $layout-spacing;
        border-radius: layout.$border-radius * $layout-spacing / 2;
        @include spacing($vertical, layout.$spacing-base * $layout-spacing);

        .car__total {
          padding: (layout.$spacing-base * $layout-spacing / 2)
            (layout.$spacing-base * $layout-spacing);
          border-radius: 20px * $layout-spacing;
        }
      }
    }
  }
}
