@use "_colors";
@use "_mixins" as *;
@use "_layout";

$sidebar-width: 302px;
$vessel_info-height: 200px;

.authenticated {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.authenticated__sidebar {
  width: $sidebar-width;
  flex-basis: 1;
  flex-shrink: 0;
}

.authenticated__content {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  background-color: colors.$map-sea;

  .authenticated__map {
    flex-grow: 2;
  }

  .authenticated__object_info {
    flex-shrink: 0;
  }
}

@include layout.byScreen using ($layout-multiplier) {
  .authenticated__sidebar {
    width: $sidebar-width * $layout-multiplier;
  }

  .authenticated__object_info {
    min-height: 20%;
  }
}
