@use "_colors";
@use "_mixins" as *;
@use "_layout";

.segment_control {
  display: flex;

  @include layout.byScreen using ($layout-multiplier) {
    @include spacing($horizontal, layout.$spacing-base * $layout-multiplier);
  }
}

.segment_control__item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: colors.$gray;

  flex-basis: 0;
  flex-grow: 2;

  cursor: pointer;

  &--selected {
    font-weight: bold;
  }

  @include layout.byScreen using ($layout-multiplier) {
    height: 54px * $layout-multiplier;
    font-size: 14px * $layout-multiplier;

    border-bottom: (2px * $layout-multiplier) solid colors.$light-gray;

    &--selected {
      border-bottom: (2px * $layout-multiplier) solid colors.$red;
    }
  }
}

.segment_control__span {
  user-select: none;
  font-size: inherit;
  color: inherit;
}
