@use "_mixins" as *;
@use "_layout";

$family-name: "Porsche Next";

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Regular.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Regular.woff2")
      format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-BoldItalic.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-BoldItalic.woff2")
      format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-BoldItalic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Thin.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Thin.woff2") format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-ThinItalic.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-ThinItalic.woff2")
      format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-ThinItalic.woff")
      format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Bold.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Bold.woff2") format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $family-name;
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Italic.eot");
  src: url("../../assets/fonts/PorscheNext/PorscheNext-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Italic.woff2")
      format("woff2"),
    url("../../assets/fonts/PorscheNext/PorscheNext-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $family-name;
  font-weight: 300;
  line-height: normal;
}

@include layout.byScreen using ($layout-spacing) {
  h1 {
    font-size: 30px * $layout-spacing;
  }
}

p,
span,
input,
textarea {
  font-size: inherit;
  font-family: inherit;
}

a {
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
}
