@use "_colors";
@use "_mixins" as *;
@use "_layout";

.object_info__section {
  display: flex;

  &:last-child .object_info__border {
    display: none;
  }
}

@include layout.byScreen using ($layout-spacing) {
  .object_info__content {
    display: flex;
    flex-direction: column;
    padding: layout.$spacing-base * 2 * $layout-spacing;
    color: white;
    flex-grow: 2;
    @include spacing($vertical, layout.$spacing-base * $layout-spacing);
  }

  .object_info__border {
    max-width: 2px * $layout-spacing;
    min-width: 2px * $layout-spacing;

    background: rgb(128, 128, 128);
    background: linear-gradient(
      0deg,
      rgba(128, 128, 128, 0) 10%,
      rgba(128, 128, 128, 1) 50%,
      rgba(128, 128, 128, 0) 90%
    );
  }
}
