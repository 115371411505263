@use '@styles/lib/reset.min.scss';

@use '@styles/globals/_colors';
@use '@styles/globals/_layout';
@use '@styles/globals/_fonts';

html,
body,
:global(#root) {
  color: colors.$text-black;
  font-family: fonts.$family-name;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  height: 100%;
  line-height: normal;

  @include layout.byScreen using ($layout-multiplier) {
    font-size: (16px * $layout-multiplier);
  }

  // This will ideally set a standard on renders between browsers (Chrome and
  // Safari on macOS). There are some arguments against it, so we might need to
  // consider removing it in the near future.
  // More info: https://usabilitypost.com/2012/11/05/stop-fixing-font-smoothing/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:after,
*:before {
  // Small hack to help make our sizes more consistent:
  // https://www.paulirish.com/2012/box-sizing-border-box-ftw/
  box-sizing: border-box;
}
