@use "_colors";
@use "_mixins" as *;
@use "_layout";

@include layout.byScreen using ($layout-spacing) {
  .object_info {
    margin: 0 (layout.$spacing-base * $layout-spacing);

    position: relative;
    border-top-left-radius: layout.$border-radius * $layout-spacing;
    border-top-right-radius: layout.$border-radius * $layout-spacing;
    background-color: colors.$light-gray;
  }

  .object_info__content {
    width: 100%;
    height: 100%;
    @include spacing($vertical, layout.$spacing-base * $layout-spacing);
  }

  .object_info__close_icon {
    position: absolute;
    top: layout.$spacing-base * $layout-spacing;
    right: layout.$spacing-base * $layout-spacing;
    padding: layout.$spacing-base * $layout-spacing / 2;
    color: black;
    background-color: white;
    font-size: 24px * $layout-spacing;
    border-radius: 30px * $layout-spacing;
    cursor: pointer;
  }
}
