@use "_colors";
@use "_mixins" as *;
@use "_layout";

.vessel_list {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .list__section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .section__header {
      display: flex;
      justify-content: space-between;
      color: white;

      .header__port {
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
      }

      .total__value {
        font-weight: bold;
      }
    }

    .section__vessels {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .section__vessel {
      display: flex;
      align-items: center;
      background-color: colors.$light-gray;
      color: white;
      transition: background-color ease-out 80ms;
      cursor: pointer;

      .vessel__name {
        color: inherit;
        font-weight: bold;
        line-height: normal;
      }

      .vessel__date {
        margin-left: auto;
        color: inherit;
        flex-shrink: 0;
      }

      .vessel__total {
        color: white;
        background-color: colors.$lighter-gray;
        font-weight: bold;
        flex-shrink: 0;
      }

      &:hover {
        background-color: rgba(colors.$light-gray, 0.8);
        transition: background-color ease-out 80ms;
      }

      &--selected {
        background-color: white;
        color: colors.$gray;

        &:hover {
          background-color: rgba(white, 0.9);
        }
      }
    }
  }

  @include layout.byScreen using ($layout-multiplier) {
    padding: layout.$spacing-base * 2 * $layout-multiplier;
    @include spacing($vertical, layout.$spacing-base * 3 * $layout-multiplier);

    .list__section {
      @include spacing($vertical, layout.$spacing-base * $layout-multiplier);

      .section__header {
        padding: 0 (layout.$spacing-base * $layout-multiplier);
      }

      .section__vessels {
        border-radius: (layout.$border-radius * $layout-multiplier) / 2;
      }

      .section__vessel {
        font-size: 16px * $layout-multiplier;
        padding: layout.$spacing-base * $layout-multiplier;
        @include spacing(
          $horizontal,
          (layout.$spacing-base * $layout-multiplier)
        );

        .vessel__total {
          padding: (layout.$spacing-base * $layout-multiplier / 2)
            (layout.$spacing-base * $layout-multiplier);
          border-radius: 20px * $layout-multiplier;
        }
      }
    }
  }
}
