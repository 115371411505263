@use "_colors";
@use "_mixins" as *;
@use "_layout";

@include layout.byScreen using ($layout-spacing) {
  .object_info__text {
    display: flex;
    font-size: 16px * $layout-spacing;
    flex-shrink: 0;

    .object_info__description {
      font-weight: bold;
    }

    &--row {
      flex-direction: row;
      justify-content: space-between;
    }

    &--column {
      flex-direction: column;
      @include spacing($vertical, layout.$spacing-base * $layout-spacing / 2);
    }
  }
}
