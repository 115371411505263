@use '_colors';
@use '_layout';
@use '_mixins' as *;
@use '_keyframes' as *;

.network_state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: layout.$spacing-base;

  @include spacing($vertical, layout.$spacing-base * 2);
}

.network_state__spinner {
  animation: spin 2s infinite linear;
}

.network_state__message {
  padding: layout.$spacing-base * 1.5 layout.$spacing-base * 2;
  color: colors.$text-dark-gray;
  border-radius: 4px;
  line-height: 22px;

  &--error {
    background-color: colors.$aqua-red;
  }

  &--info {
    background-color: colors.$aqua-blue;
  }
}
